import {
  Control,
  FieldArrayWithId,
  FieldPath,
  useFieldArray,
  useForm,
  UseFormReturn,
  useWatch,
} from "react-hook-form";
import { DndContext } from "@dnd-kit/core";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";

import {
  CalculatorModeString,
  Commodity,
  Port,
  SelectOption,
  Term,
} from "../../_components/types";
import {
  Dispatch,
  Fragment,
  memo,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  defaultMultiFormValues,
  MultiModeFormSchema,
  MultiModeInput,
  MultiModeTransformSchema,
  PortInput,
  PortOperationInput,
} from "../../_components/schema";
import { PortDropdown } from "../../_components/VoyagePortInputGroup";
import Icon from "../../_components/Icon";
import {
  CalcContext,
  getMonthsArrayWithLabels,
  OperationType,
  useMultiModeSuggestions,
} from "@copmer/calculator-widget";
import { Button } from "@/components/ui/button";
import {
  CalculatorDropdown,
  CalculatorInput,
  InputWrapper,
} from "../../_components/Inputs";
import { numericOnChange } from "../../_components/helpers";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { isSet } from "../../_components/form";
import CalculationLookupSummary from "../../_components/CalculationLookupSummary";
import { createPortal } from "react-dom";
import { FormField } from "@/components/ui/form";
import {
  CalculatorModeSelector,
  ModeToggleGroupItem,
} from "../../_components/ModeSelector";
import { useKeyboardSubmit } from "../../_components/use-keyboard-submit";
import { transformDataWithSchemaTransforms } from "../../_components/zod-transform";
import {
  MultiAdvancedOperationInputs,
  MultiAmountField,
  MultiCommodityField,
  MultiOperationCommodityPriceField,
  MultiOperationToleranceField,
  MultiOperationTypeField,
} from "./fields";
import { cn } from "@/lib/utils";
import { ToggleGroup } from "@/components/ui/toggle-group";

function SortableItem(props: {
  id: string;
  children: (
    args: Omit<
      ReturnType<typeof useSortable>,
      "setNodeRef" | "transform" | "transition"
    >
  ) => React.ReactNode;
}) {
  const { setNodeRef, transform, transition, ...rest } = useSortable({
    id: props.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style}>
      {props.children({ ...rest })}
    </div>
  );
}

const isEmpty = (operation: PortOperationInput) => {
  return (
    !isSet(operation.amount) &&
    !isSet(operation.commodity) &&
    !isSet(operation.cadence) &&
    !isSet(operation.terms) &&
    !isSet(operation.draft) &&
    !isSet(operation.portDA)
  );
};

const PortGroup = ({
  field,
  index,
  form,
  portOptions,
  isLoading,
  isLast,
  isLastRemaining,
  removePort,
  addPort,
  commodities,
  terms,
  errorText,
  suggestions,
  tabIndex,
  calculatorMode,
}: {
  index: number;
  field: FieldArrayWithId<MultiModeInput, "ports", "id">;
  form: UseFormReturn<MultiModeInput>;
  portOptions: SelectOption[];
  isLoading?: boolean;

  calculatorMode: CalculatorModeString;

  isLast?: boolean;
  isLastRemaining?: boolean;

  removePort: (index: number) => void;
  addPort: (index?: number) => void;

  errorText?: string;

  commodities: Commodity[];
  terms: SelectOption[];

  suggestions: PortOperationInput[];

  tabIndex?: number;
}) => {
  const {
    fields: operationFields,
    append,
    update,
    move,
    remove,
  } = useFieldArray({
    control: form.control,
    name: `ports.${index}.operations`,
  });

  const modifiers = [restrictToVerticalAxis, restrictToParentElement];

  const maybeAppend = useCallback(
    (item: PortOperationInput) => {
      for (const [i, operation] of operationFields.entries()) {
        if (
          isEmpty(operation) ||
          (operation.commodity === item.commodity &&
            operation.operation === item.operation)
        ) {
          update(i, {
            ...item,

            amount: (operation.amount ?? 0) + (item.amount ?? 0),
          });
          return;
        }
      }

      append(item);
    },
    [operationFields, append, update]
  );

  return (
    <Fragment key={field.id}>
      <SortableItem id={field.id}>
        {({ attributes, listeners }) => (
          <div className="flex flex-col gap-4">
            <div className="flex gap-4 items-center justify-start">
              <div className="font-light text-sm leading-none text-white">
                Port {index + 1}
              </div>

              {isLastRemaining ? null : (
                <div>
                  <Button
                    type="button"
                    variant="darkText"
                    className="py-1 text-frGrey-500 hover:text-frGrey-400 px-0"
                    onClick={() => removePort(index)}
                    tabIndex={tabIndex}
                  >
                    <Icon icon="SquareRoundedMinus" className="w-4 h-4 mr-1" />
                    Remove Port
                  </Button>
                </div>
              )}

              <div className="flex-grow" />

              <div>
                <Button
                  type="button"
                  variant="darkText"
                  className="py-1 text-primary-200 hover:text-primary-100 px-0"
                  onClick={() => addPort(index)}
                  tabIndex={tabIndex}
                >
                  <Icon icon="Plus" className="w-4 h-4 mr-1" />
                  Add Port
                </Button>
              </div>
            </div>
            <div className="bg-frGrey-985 rounded-3xl py-2 px-2 flex items-center space-x-4 select-none w-full">
              <div className="self-start mt-3">
                <div
                  className="w-6 h-6 rounded text-frGrey-400"
                  {...attributes}
                  {...listeners}
                >
                  <Icon icon="Menu" className="w-6 h-6" />
                </div>
              </div>
              <div className="w-full">
                <div>
                  <div>
                    <div className="rounded-3xl p-2 bg-frGrey-990 flex flex-col gap-2">
                      <FormField
                        control={form.control}
                        name={`ports.${index}.port`}
                        render={({ field, fieldState: { error } }) => (
                          <PortDropdown
                            label="Port"
                            id={`ports.${index}.port`}
                            value={
                              portOptions.find(
                                (port) => port.value === field.value
                              ) ?? null
                            }
                            setValue={(value) => {
                              field.onChange(value?.value ?? null);
                            }}
                            ports={portOptions}
                            isLoading={isLoading}
                            error={error?.message ?? errorText ?? undefined}
                            className="mb-2"
                            tabIndex={tabIndex}
                          />
                        )}
                      />

                      <DndContext
                        modifiers={modifiers}
                        onDragEnd={(event) => {
                          const { active, over, ...rest } = event;

                          if (over && active.id !== over?.id) {
                            const activeIndex =
                              active.data.current?.sortable?.index;
                            const overIndex =
                              over.data.current?.sortable?.index;
                            if (
                              activeIndex !== undefined &&
                              overIndex !== undefined
                            ) {
                              move(activeIndex, overIndex);
                            }
                          }
                        }}
                      >
                        <SortableContext items={operationFields}>
                          {operationFields.map(
                            (operationField, operationIndex) => {
                              return (
                                <Fragment key={operationField.id}>
                                  <MultiOperationDNDWrapper
                                    operationField={operationField}
                                    index={index}
                                    operationIndex={operationIndex}
                                    remove={remove}
                                    control={form.control}
                                    calculatorMode={calculatorMode}
                                    commodities={commodities}
                                    terms={terms}
                                    tabIndex={tabIndex}
                                    isLoading={isLoading}
                                  />
                                </Fragment>
                              );
                            }
                          )}
                        </SortableContext>
                      </DndContext>

                      <div className="flex gap-4">
                        {suggestions.length > 0 ? (
                          <div className="flex flex-wrap gap-4 gap-y-0">
                            {suggestions.map((suggestion, index) => (
                              <Button
                                type="button"
                                key={index}
                                variant="darkText"
                                className="py-1 text-primary-200 hover:text-primary-100 px-0"
                                onClick={() => maybeAppend(suggestion)}
                                tabIndex={tabIndex}
                              >
                                {`Add ${
                                  suggestion.operation === OperationType.L
                                    ? "load"
                                    : "discharge"
                                } ${suggestion.amount} ${suggestion.commodity}`}
                              </Button>
                            ))}
                          </div>
                        ) : null}

                        <Button
                          type="button"
                          variant="darkText"
                          className="py-1 text-primary-200 hover:text-primary-100 px-0"
                          onClick={() => {
                            append({
                              operation:
                                index > 0 ? OperationType.D : OperationType.L,
                              commodity: "",
                              amount: "",
                              cadence: "",
                              terms: "",
                              draft: "",
                              portDA: "",
                            } as any as PortOperationInput);
                          }}
                          tabIndex={tabIndex}
                        >
                          <Icon icon="Plus" className="w-4 h-4 mr-1" />
                          Add Port operation
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </SortableItem>

      {isLast ? (
        <div className="w-full flex items-center">
          <div className="flex-grow" />

          <div>
            <Button
              type="button"
              variant="darkText"
              className="py-1 text-primary-200 hover:text-primary-100 px-0"
              onClick={() => addPort()}
              tabIndex={tabIndex}
            >
              <Icon icon="Plus" className="w-4 h-4 mr-1" />
              Add a Port
            </Button>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

const MultiOperationDNDWrapper = ({
  operationField,
  index,
  operationIndex,

  control,

  calculatorMode,

  commodities,
  terms,

  remove,

  isLoading,
  tabIndex,
}: {
  operationField: FieldArrayWithId<MultiModeInput, "operations", "id">;

  index: number;
  operationIndex: number;

  control: Control<MultiModeInput>;

  calculatorMode: CalculatorModeString;

  commodities: Commodity[];
  terms: SelectOption[];

  remove: (operationIndex: number) => void;

  isLoading?: boolean;
  tabIndex?: number;
}) => {
  const ctx = useContext(CalcContext);

  const operationType = useWatch({
    control,
    name: `ports.${index}.operations.${operationIndex}.operation`,
  });

  return (
    <SortableItem id={operationField.id}>
      {({ attributes, listeners }) => (
        <div className="bg-frGrey-880 rounded-3xl py-2 px-2 relative">
          <div className="flex items-center mt-1 bigscreen:hidden">
            <div
              className="w-6 h-6 mr-2 select-none self-start text-frGrey-400"
              {...attributes}
              {...listeners}
            >
              <Icon icon="Menu" className="w-6 h-6" />
            </div>

            <div className="absolute w-6 h-6 top-3 right-2">
              <Button
                type="button"
                variant="icon"
                size="snug"
                className="hover:border-transparent text-frGrey-400"
                onClick={() => remove(operationIndex)}
              >
                <Icon icon="SquareRoundedMinus" className="w-6 h-6" />
              </Button>
            </div>

            <div>
              <div className="text-black">
                <div>
                  <MultiOperationTypeField
                    control={control}
                    index={index}
                    operationIndex={operationIndex}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2 mt-4 bigscreen:mt-2 max-[600px]:grid-cols-1 bigscreen:grid-cols-4 bigscreen:grid-cols-[30%,29%,35%,auto]">
            <div className="hidden bigscreen:flex">
              <div
                className="w-6 h-6 mr-2 mt-5 select-none self-start text-frGrey-400 "
                {...attributes}
                {...listeners}
              >
                <Icon icon="Menu" className="w-6 h-6" />
              </div>

              <InputWrapper isRequired label="Operation" className="pr-2">
                <MultiOperationTypeField
                  control={control}
                  index={index}
                  operationIndex={operationIndex}
                />
              </InputWrapper>
            </div>

            <div>
              <MultiCommodityField
                control={control}
                index={index}
                operationIndex={operationIndex}
                commodities={commodities}
                isLoading={isLoading}
                tabIndex={tabIndex}
              />
            </div>

            <div
              className={cn({
                "grid gap-2": ctx?.isCFROptimizer,
                "grid-cols-3 bigscreen:grid-cols-[auto,23%,auto]":
                  operationType === OperationType.L,
                "grid-cols-1": operationType !== OperationType.L,
              })}
            >
              <MultiAmountField
                control={control}
                index={index}
                operationIndex={operationIndex}
                tabIndex={tabIndex}
              />

              {ctx?.isCFROptimizer ? (
                <>
                  {operationType === OperationType.L ? (
                    <MultiOperationToleranceField
                      control={control}
                      index={index}
                      operationIndex={operationIndex}
                      tabIndex={tabIndex}
                    />
                  ) : null}

                  {operationType === OperationType.L ? (
                    <MultiOperationCommodityPriceField
                      control={control}
                      index={index}
                      operationIndex={operationIndex}
                      tabIndex={tabIndex}
                    />
                  ) : null}
                </>
              ) : null}
            </div>

            <div className="hidden bigscreen:block text-right">
              <label className="font-light font-roboto flex gap-1 items-center text-xs">
                &nbsp;
              </label>

              <Button
                type="button"
                variant="icon"
                size="snug"
                className="hover:border-transparent text-frGrey-400 mt-1"
                onClick={() => remove(operationIndex)}
              >
                <Icon icon="SquareRoundedMinus" className="w-6 h-6" />
              </Button>
            </div>
          </div>

          {calculatorMode === CalculatorModeString.Advanced ? (
            <div className="mt-2 grid gap-2 grid-cols-4 max-[600px]:grid-cols-2 max-[500px]:grid-cols-1">
              <MultiAdvancedOperationInputs
                control={control}
                index={index}
                operationIndex={operationIndex}
                tabIndex={tabIndex}
                isLoading={isLoading}
                terms={terms}
              />
            </div>
          ) : null}
        </div>
      )}
    </SortableItem>
  );
};

function MultiModeHooksRaw({
  control,
  setSuggestions,
}: {
  control: Control<MultiModeInput>;
  setSuggestions: Dispatch<SetStateAction<Map<number, PortOperationInput[]>>>;
}) {
  const state = transformDataWithSchemaTransforms(
    useWatch({
      control,
    }),
    MultiModeTransformSchema
  );

  useMultiModeSuggestions<MultiModeInput, PortOperationInput>(
    state as any,
    setSuggestions,
    {
      operation: OperationType.L,
      amount: 0,
      commodity: "",
      cadence: "",
      terms: "",
      draft: "",
      portDA: "",
      useVesselGear: "",

      tolerance: undefined,
      commodityPrice: undefined,
    } as any as PortOperationInput
  );

  return null;
}

const MultiModeHooks = memo(MultiModeHooksRaw);

const mergeOperations = (
  a: PortOperationInput[],
  b: PortOperationInput[] | undefined
): PortOperationInput[] => {
  if (!b || b.length === 0) {
    return a;
  }

  const res: PortOperationInput[] = [];

  for (const [i, operation] of a.entries()) {
    res.push({
      ...operation,
      ...b[i],
    });
  }

  if (b.length > a.length) {
    for (const [i, operation] of b.slice(a.length).entries()) {
      res.push(operation);
    }
  }

  return res;
};

const mergePorts = (a: PortInput[], b: PortInput[] | undefined) => {
  if (!b || b.length === 0) {
    return a;
  }

  const res: PortInput[] = [];

  for (const [i, port] of a.entries()) {
    const resPort = {
      ...port,
      ...b[i],

      operations: mergeOperations(port.operations, b?.[i]?.operations),
    };

    res.push(resPort);
  }

  if (b.length > a.length) {
    for (const [i, port] of b.slice(a.length).entries()) {
      res.push(port);
    }
  }

  return res;
};

export interface MultiCalculatorFormProps {
  isLoading: boolean;

  commodities: Commodity[];
  ports: Port[];
  terms: Term[];

  onSubmit?: (data: MultiModeInput) => void;

  urlValues?: Partial<MultiModeInput>;

  minimized?: boolean;

  summaryNode: HTMLDivElement | null;
  mobileSummaryNode?: HTMLElement | null;

  setSortModalOpen?: (isOpen: boolean) => void;
  setFilterModalOpen?: (isOpen: boolean) => void;
}

export default function MultiCalculatorForm({
  ports,
  terms,
  commodities,
  isLoading,
  onSubmit,
  urlValues,
  minimized,
  summaryNode,
  mobileSummaryNode,
  setSortModalOpen,
  setFilterModalOpen,
}: MultiCalculatorFormProps) {
  const ctx = useContext(CalcContext);

  const monthsArray: SelectOption[] = ctx?.isCFROptimizer
    ? getMonthsArrayWithLabels()
    : [];

  const form = useForm<MultiModeInput>({
    resolver: zodResolver(MultiModeFormSchema),

    defaultValues: {
      ...(defaultMultiFormValues as any as MultiModeInput),
      ...urlValues,

      ports: mergePorts(
        defaultMultiFormValues.ports as any as MultiModeInput["ports"],
        urlValues?.ports ?? []
      ),
    },
  });

  const doSetValue = useCallback(
    (key: string | string[], value: any) => {
      if (!isSet(value)) {
        return;
      }

      if (Array.isArray(value)) {
        for (const [i, subValue] of value.entries()) {
          doSetValue([...key, `${i}`], subValue);
        }
      } else if (typeof value === "object" && value) {
        for (const [subKey, subValue] of Object.entries(value)) {
          doSetValue([...key, subKey], subValue);
        }
      } else {
        form.setValue(
          (Array.isArray(key)
            ? key.join(".")
            : key) as FieldPath<MultiModeInput>,
          value
        );
      }
    },
    [form.setValue]
  );

  useEffect(() => {
    for (const [key, value] of Object.entries(urlValues ?? {})) {
      doSetValue(key, value);
    }
  }, [urlValues, doSetValue]);

  const formRef = useRef<HTMLFormElement>(null);

  const editingStarted = useRef(false);
  const [isEditing, setIsEditing] = useState(false);

  const startEditing = useCallback(() => {
    // Tell the form to focus on the first input when editing starts
    editingStarted.current = true;

    setIsEditing(true);
  }, [setIsEditing]);

  useLayoutEffect(() => {
    if (isEditing && editingStarted.current) {
      editingStarted.current = false;

      const firstInput = formRef.current?.querySelector?.("input, select") as
        | HTMLInputElement
        | HTMLSelectElement
        | null;
      if (firstInput) {
        firstInput?.focus?.();
      }
    }
  }, [isEditing]);

  useKeyboardSubmit(formRef, isEditing || !minimized);

  const baseMinimized = useRef(minimized);
  useEffect(() => {
    if (minimized && !baseMinimized.current) {
      setIsEditing(false);
    }

    baseMinimized.current = minimized;
  }, [minimized]);

  const [suggestions, setSuggestions] = useState<
    Map<number, PortOperationInput[]>
  >(new Map());

  const { handleSubmit, control, formState } = form;

  const modifiers = [restrictToVerticalAxis, restrictToParentElement];

  const { fields, move, remove, append, insert } = useFieldArray({
    control,
    name: "ports",
  });

  const portOptions = useMemo(
    () =>
      ports.map((port) => ({
        value: port.unlocode,
        label: `${port.displayName} (${port.unlocode})`,
      })),
    [ports]
  );

  const termsOptions = useMemo(
    () =>
      terms.map((term) => ({
        label: `${term.code} (${term.description} ${term.timeFactor})`,
        value: term.code,
      })),
    [terms]
  );

  const calculatorMode = form.watch("mode");

  if (minimized && !isEditing) {
    if (!summaryNode) {
      return null;
    }

    return (
      <>
        {createPortal(
          <CalculationLookupSummary
            form={form}
            commodities={commodities}
            ports={ports}
            onStartEditing={() => startEditing()}
            setSortModalOpen={setSortModalOpen}
            setFilterModalOpen={setFilterModalOpen}
            formType="multi"
          />,
          summaryNode
        )}

        {mobileSummaryNode
          ? createPortal(
              <CalculationLookupSummary
                form={form}
                commodities={commodities}
                ports={ports}
                onStartEditing={() => startEditing()}
                isMobile
                setSortModalOpen={setSortModalOpen}
                setFilterModalOpen={setFilterModalOpen}
                formType="multi"
              />,
              mobileSummaryNode
            )
          : null}
      </>
    );
  }

  return (
    <form
      onSubmit={handleSubmit((values) => {
        onSubmit?.(values);

        setIsEditing(false);
      })}
      className="w-full max-w-screen-md bigscreen:max-w-bigscreencontainer mx-auto w-full px-4"
      ref={formRef}
    >
      <MultiModeHooks control={control} setSuggestions={setSuggestions} />

      <div className="-mx-4 -mt-6 py-4 border-b border-frGrey-800 px-4 bg-white/3 md:bg-transparent">
        <div className="bigscreen:w-auto bigscreen:flex bigscreen:items-start">
          <FormField
            control={form.control}
            name="mode"
            render={({ field, fieldState: { error } }) => (
              <CalculatorModeSelector
                calculatorMode={field.value as CalculatorModeString}
                setCalculatorMode={field.onChange}
                background={false}
                itemClassName="bigscreen:px-7"
              />
            )}
          />
        </div>
        {calculatorMode === CalculatorModeString.Advanced ? (
          <div>
            <div className="flex flex-col md:flex-row gap-2">
              <div className="md:w-1/2">
                <FormField
                  control={form.control}
                  name="hire"
                  render={({ field, fieldState: { error } }) => (
                    <CalculatorInput
                      type="text"
                      label="Hire"
                      placeholder="Hire"
                      suffix="$/ day"
                      inputProps={{
                        ...field,
                        inputMode: "decimal",
                        value: field.value ?? "",
                      }}
                      onChange={numericOnChange(field.onChange)}
                      error={error?.message}
                      tabIndex={0}
                    />
                  )}
                />
              </div>

              <div className="flex gap-2 md:w-1/2">
                <FormField
                  control={form.control}
                  name="sfoPrice"
                  render={({ field, fieldState: { error } }) => (
                    <CalculatorInput
                      type="text"
                      id="sfoPrice"
                      label="Bunker (VLSFO)"
                      placeholder="Price"
                      suffix="$/ mt"
                      inputProps={{
                        ...field,
                        inputMode: "decimal",
                        value: field.value ?? "",
                      }}
                      onChange={numericOnChange(field.onChange)}
                      error={error?.message}
                      tabIndex={0}
                    />
                  )}
                />

                <FormField
                  control={form.control}
                  name="mgoPrice"
                  render={({ field, fieldState: { error } }) => (
                    <CalculatorInput
                      type="text"
                      id="mgoPrice"
                      label="Bunker (LSMGO)"
                      placeholder="Price"
                      suffix="$/ mt"
                      inputProps={{
                        ...field,
                        inputMode: "decimal",
                        value: field.value ?? "",
                      }}
                      onChange={numericOnChange(field.onChange)}
                      error={error?.message}
                      tabIndex={0}
                    />
                  )}
                />
              </div>
            </div>

            <div className="mt-4">
              <p className="text-sm text-center font-light text-frGrey-500 md:text-left">
                If optional values are not specified, the best data or
                estimation is used.
              </p>
            </div>
          </div>
        ) : null}
      </div>

      <div className="grid gap-5 w-full mt-6">
        <DndContext
          modifiers={modifiers}
          onDragEnd={(event) => {
            const { active, over, ...rest } = event;

            if (over && active.id !== over?.id) {
              const activeIndex = active.data.current?.sortable?.index;
              const overIndex = over.data.current?.sortable?.index;
              if (activeIndex !== undefined && overIndex !== undefined) {
                move(activeIndex, overIndex);
              }
            }
          }}
        >
          <SortableContext items={fields}>
            {fields.map((field, index) => (
              <PortGroup
                key={field.id}
                index={index}
                field={field}
                form={form}
                portOptions={portOptions}
                isLoading={isLoading}
                isLast={index === fields.length - 1}
                isLastRemaining={fields.length - 1 === 0}
                removePort={(index) => remove(index)}
                addPort={(index?: number) => {
                  if (index !== undefined) {
                    insert(index, { port: "", operations: [] });
                  } else {
                    append({ port: "", operations: [] });
                  }
                }}
                commodities={commodities}
                terms={termsOptions}
                errorText={
                  formState.errors.ports?.[index]?.operations?.message ??
                  formState.errors.ports?.[index]?.operations?.root?.message
                }
                suggestions={suggestions.get(index) ?? []}
                tabIndex={0}
                calculatorMode={calculatorMode}
              />
            ))}
          </SortableContext>
        </DndContext>

        {formState.errors.ports?.root?.message ? (
          <Alert variant="destructive">
            <ExclamationTriangleIcon className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>
              {formState.errors.ports?.root?.message}
            </AlertDescription>
          </Alert>
        ) : null}

        {ctx?.isCFROptimizer ? (
          <div className="flex flex-col items-end justify-center gap-2">
            <div>
              <FormField
                control={control}
                name="period"
                render={({ field }) => (
                  <CalculatorDropdown
                    value={
                      monthsArray.find((m) => m.value === field.value) ??
                      monthsArray[0]
                    }
                    setValue={(value) => {
                      field.onChange(value?.value ?? monthsArray[0]);
                    }}
                    choices={monthsArray}
                    labelClassName="min-w-[200px]"
                    label="Shipment Month"
                    placeholder="Period"
                    isRequired
                    emptyText="No results found."
                    noClear
                  />
                )}
              />
            </div>
            <div>
              <FormField
                control={control}
                name="optimizerMode"
                render={({ field }) => (
                  <InputWrapper
                    id="optimizerMode"
                    label="Route Optimization"
                    tooltip="Optimize the route order to minimize the total cost."
                    plainLabel
                  >
                    <ToggleGroup
                      type="single"
                      className="bg-frGrey-985 p-0.5 border border-frGrey-790 rounded-full"
                      value={field.value ?? "optimize"}
                      onValueChange={field.onChange}
                      tabIndex={0}
                    >
                      <ModeToggleGroupItem
                        value="optimize"
                        aria-label="Optimize Route Order"
                      >
                        Optimize Route Order
                      </ModeToggleGroupItem>
                      <ModeToggleGroupItem
                        value="fixed"
                        aria-label="Fixed Route Order"
                      >
                        Fixed Route Order
                      </ModeToggleGroupItem>
                    </ToggleGroup>
                  </InputWrapper>
                )}
              />
            </div>
          </div>
        ) : null}

        <div className="flex gap-4 pt-5 pb-10 bigscreen:w-1/2 bigscreen:justify-self-end">
          <Button
            className="w-full rounded-full border-primary-200"
            size="xs"
            type="reset"
            variant="darkOutline"
            onClick={() =>
              form.reset({
                ...(defaultMultiFormValues as any as MultiModeInput),
              })
            }
            tabIndex={0}
          >
            Reset
          </Button>

          <Button
            className="w-full rounded-full"
            type="submit"
            variant="darkFilled"
            size="xs"
            tabIndex={0}
          >
            Calculate
          </Button>
        </div>
      </div>
    </form>
  );
}
