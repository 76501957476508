import { ToggleGroup } from "@/components/ui/toggle-group";
import { OperationType } from "@copmer/calculator-widget";
import { ModeToggleGroupItem } from "../../_components/ModeSelector";
import Icon from "../../_components/Icon";

export const OperationModeSelector = ({
  operationType,
  setOperationType,
}: {
  operationType: OperationType;
  setOperationType: (value: OperationType) => void;
}) => {
  return (
    <div className="p-0">
      <ToggleGroup
        type="single"
        className="bg-frGrey-985 p-0.5 border border-frGrey-790 rounded-full"
        value={operationType}
        onValueChange={(newValue: string | null) => {
          if (newValue) {
            setOperationType(newValue as OperationType);
          }
        }}
        tabIndex={0}
      >
        <ModeToggleGroupItem value={OperationType.L} aria-label="Load">
          <Icon icon="Download" className="h-4 w-4 mr-1" />
          Load
        </ModeToggleGroupItem>
        <ModeToggleGroupItem value={OperationType.D} aria-label="Discharge">
          <Icon icon="Upload" className="h-4 w-4 mr-1" />
          Discharge
        </ModeToggleGroupItem>
      </ToggleGroup>
    </div>
  );
};
