import { Control } from "react-hook-form";
import { MultiModeInput } from "../../_components/schema";
import { FormField } from "@/components/ui/form";
import CommoditySelector from "../../_components/CommoditySelector";
import { numericOnChange } from "../../_components/helpers";
import { CalculatorDropdown, CalculatorInput } from "../../_components/Inputs";
import { OperationModeSelector } from "./OperationModeSelector";
import { OperationType } from "@copmer/calculator-widget";

export const MultiOperationTypeField = ({
  control,
  index,
  operationIndex,
}: {
  control: Control<MultiModeInput, any>;
  index: number;
  operationIndex: number;
}) => (
  <FormField
    control={control}
    name={`ports.${index}.operations.${operationIndex}.operation`}
    render={({ field }) => (
      <OperationModeSelector
        operationType={field.value as OperationType}
        setOperationType={field.onChange}
      />
    )}
  />
);

export const MultiCommodityField = ({
  control,
  index,
  operationIndex,

  commodities,

  isLoading,
  tabIndex,
}: {
  control: Control<MultiModeInput, any>;
  index: number;
  operationIndex: number;

  commodities: Array<{
    commodity: string;
    stowageFactorCBFT: number;
  }>;

  isLoading?: boolean;
  tabIndex?: number;
}) => (
  <FormField
    control={control}
    name={`ports.${index}.operations.${operationIndex}.commodity`}
    render={({ field, fieldState: { error } }) => (
      <CommoditySelector
        commodities={commodities}
        isLoading={isLoading}
        value={field.value ?? ""}
        setValue={field.onChange}
        error={error?.message}
        tabIndex={tabIndex}
      />
    )}
  />
);

export const MultiAmountField = ({
  control,
  index,
  operationIndex,

  tabIndex,
}: {
  control: Control<MultiModeInput, any>;
  index: number;
  operationIndex: number;

  tabIndex?: number;
}) => (
  <FormField
    control={control}
    name={`ports.${index}.operations.${operationIndex}.amount`}
    render={({ field, fieldState: { error } }) => (
      <CalculatorInput
        id={`ports.${index}.operations.${operationIndex}.amount`}
        type="text"
        label="Quantity"
        isRequired
        placeholder="Quantity"
        suffix="mt"
        inputProps={{
          ...field,
          inputMode: "decimal",
          value: field.value ?? "",
        }}
        onChange={numericOnChange(field.onChange)}
        error={error?.message}
        tabIndex={tabIndex}
      />
    )}
  />
);

export const MultiOperationToleranceField = ({
  control,
  index,
  operationIndex,

  tabIndex,
}: {
  control: Control<MultiModeInput, any>;
  index: number;
  operationIndex: number;

  tabIndex?: number;
}) => (
  <FormField
    control={control}
    name={`ports.${index}.operations.${operationIndex}.tolerance`}
    render={({ field, fieldState: { error } }) => (
      <CalculatorInput
        id={`ports.${index}.operations.${operationIndex}.tolerance`}
        type="text"
        label="Tolerance"
        plainLabel
        placeholder="0"
        suffix="%"
        inputProps={{
          ...field,
          inputMode: "decimal",
          value: field.value ?? "",
        }}
        onChange={numericOnChange(field.onChange)}
        error={error?.message}
        tabIndex={tabIndex}
      />
    )}
  />
);

export const MultiOperationCommodityPriceField = ({
  control,
  index,
  operationIndex,

  tabIndex,
}: {
  control: Control<MultiModeInput, any>;
  index: number;
  operationIndex: number;

  tabIndex?: number;
}) => (
  <FormField
    control={control}
    name={`ports.${index}.operations.${operationIndex}.commodityPrice`}
    render={({ field, fieldState: { error } }) => (
      <CalculatorInput
        id={`ports.${index}.operations.${operationIndex}.commodityPrice`}
        type="text"
        label="FOB price"
        plainLabel
        placeholder="0"
        suffix="$/mt"
        inputProps={{
          ...field,
          inputMode: "decimal",
          value: field.value ?? "",
        }}
        onChange={numericOnChange(field.onChange)}
        error={error?.message}
        tabIndex={tabIndex}
      />
    )}
  />
);

export const MultiAdvancedOperationInputs = ({
  control,
  index,
  operationIndex,
  terms,

  isLoading,
  tabIndex,
}: {
  control: Control<MultiModeInput, any>;
  index: number;
  operationIndex: number;
  terms: Array<{ value: string; label: string }>;

  isLoading?: boolean;
  tabIndex?: number;
}) => (
  <>
    <FormField
      control={control}
      name={`ports.${index}.operations.${operationIndex}.cadence`}
      render={({ field, fieldState: { error } }) => (
        <CalculatorInput
          id={`ports.${index}.operations.${operationIndex}.cadence`}
          type="text"
          label="Cadence"
          placeholder="Cadence"
          suffix="mt/ day"
          inputProps={{
            ...field,
            inputMode: "decimal",
            value: field.value ?? "",
          }}
          onChange={numericOnChange(field.onChange)}
          error={error?.message}
          tabIndex={tabIndex}
        />
      )}
    />

    <FormField
      control={control}
      name={`ports.${index}.operations.${operationIndex}.terms`}
      render={({ field, fieldState: { error } }) => (
        <CalculatorDropdown
          value={terms.find((term) => term.value === field.value) ?? null}
          setValue={(value) => {
            field.onChange(value?.value ?? null);
          }}
          choices={terms}
          label="Terms"
          placeholder="Terms"
          emptyText="No results found."
          error={error?.message}
          isLoading={isLoading}
          tabIndex={tabIndex}
        />
      )}
    />

    <FormField
      control={control}
      name={`ports.${index}.operations.${operationIndex}.draft`}
      render={({ field, fieldState: { error } }) => (
        <CalculatorInput
          id={`ports.${index}.operations.${operationIndex}.draft`}
          type="text"
          label="Draft"
          placeholder="Draft"
          suffix="m"
          inputProps={{
            ...field,
            inputMode: "decimal",
            value: field.value ?? "",
          }}
          onChange={numericOnChange(field.onChange)}
          error={error?.message}
          tabIndex={tabIndex}
        />
      )}
    />

    <FormField
      control={control}
      name={`ports.${index}.operations.${operationIndex}.portDA`}
      render={({ field, fieldState: { error } }) => (
        <CalculatorInput
          id={`ports.${index}.operations.${operationIndex}.portDA`}
          type="text"
          label="Port DA"
          placeholder="Port DA"
          suffix="$"
          inputProps={{
            ...field,
            inputMode: "decimal",
            value: field.value ?? "",
          }}
          onChange={numericOnChange(field.onChange)}
          error={error?.message}
          tabIndex={tabIndex}
        />
      )}
    />
  </>
);
