import { usePathname, useSearchParams } from "next/navigation";
import { useRouter } from "next/navigation";
import { useMemo } from "react";

import { CalculatorContextT, getAPIRoutes } from "@copmer/calculator-widget";

export const getBasePath = () => {
  return process.env.__NEXT_ROUTER_BASEPATH ?? "";
};

export const useCalculatorContext = (
  isCFROptimizer?: boolean
): CalculatorContextT => {
  const router = useRouter();
  const searchParams = useSearchParams();

  const pathName = usePathname();

  const apiRoutes = useMemo(() => {
    return getAPIRoutes(getBasePath() ?? "", false);
  }, []);

  const contextValue = useMemo(
    () => ({
      isMobileView: false,
      searchParams,
      pathName,
      router,
      permissions: [],
      apiRoutes,
      isCFROptimizer: isCFROptimizer || undefined,
    }),
    [searchParams, pathName, router, apiRoutes, isCFROptimizer]
  );

  return contextValue;
};
